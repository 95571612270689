/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useRef } from 'react'
import { classnames } from 'begonya/functions'

import { useApp } from '@/core/contexts/app'
import { useVar } from '@/core/hooks'
import { path } from '@/core/utils'

import type { ImageProps } from './types'

const Image: React.FunctionComponent<ImageProps> = (props) => {
  const attributes = { ...props, ...props.source }
  const {
    alt,
    className,
    height,
    id,
    loading = 'eager',
    src = '',
    srcSet,
    width,
    ...rest
  } = attributes

  const app = useApp()
  const imageRef = useRef<HTMLImageElement>(null)
  const isEager = useVar(loading === 'eager')
  const isLazy = useVar(loading === 'lazy')
  const classNames = classnames(
    'image',
    {
      'image-loaded': isEager,
      'image-loading': isLazy,
    },
    className
  )

  const getSrcSet = () => {
    if (srcSet) {
      if (typeof srcSet === 'string') {
        return srcSet
      }

      return srcSet
        .map(({ src, width }) => `${path.asset(app.environment, src)} ${width}`)
        .join(',')
    }
  }

  const getAttributes = () => {
    if (isLazy) {
      return {
        'data-src': path.asset(app.environment, src),
        'data-srcset': getSrcSet(),
        onLoad: handleLoad,
        ...rest,
      }
    }

    return {
      src: path.asset(app.environment, src),
      srcSet: getSrcSet(),
      ...rest,
    }
  }

  useEffect(() => {
    if (isLazy) {
      const image = imageRef.current

      if (image) {
        if ('loading' in HTMLImageElement.prototype) {
          const src = image.getAttribute('data-src')
          const srcset = image.getAttribute('data-srcset')

          image.src = src || ''
          image.srcset = srcset || ''
          image.classList.remove('layzload')
          image.removeAttribute('data-src')
          image.removeAttribute('data-srcset')
        } else {
          image.classList.add('lazyload')
        }
      }
    }
  }, [isLazy])

  const handleLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    if (event.currentTarget) {
      const parent = event.currentTarget.parentElement

      if (parent) {
        parent.classList.remove('image-loading')
        parent.classList.add('image-loaded')
      }
    }
  }

  const handleError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    if (event.currentTarget) {
      const parent = event.currentTarget.parentElement

      if (parent) {
        parent.classList.remove('image-loading')
        parent.classList.add('broken-image')
      }
    }
  }

  return (
    <div className={classNames}>
      <img
        ref={imageRef}
        className="image-node"
        alt={alt}
        width={width}
        height={height}
        loading={loading}
        onError={handleError}
        {...getAttributes()}
      />
    </div>
  )
}

export default Image
